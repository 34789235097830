/* 全局样式设置 */

input:active,
input:focus,
button:active,
button:focus {
    outline: none;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #F6F6F8 inset;
    -webkit-text-fill-color: #545C78;
}


html body {

    a,
    a:hover,
    a:visited,
    a:active {
        color: #545C78;
    }
}


/* 初始设置 */
html {
    font-size: 100px;
    // min-width: 1000px;
}

html body {
    font-size: 0.14rem;
    font-family: Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #545C78;
}

input,
input::placeholder,
textarea,
textarea::placeholder {
    color: #545C78;
    padding: 0;
}

.ant-message {
    z-index: 10001 !important;
}

/* over hidden */
.over-hidden {
    overflow: hidden !important;
}

/* 全屏撑满 */
.full-container {
    min-width: 100%;
    min-height: 100%;
    position: relative;
}

/* 省略号 */
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* cursor-pointer */
.cursor-pointer {
    cursor: pointer !important;
}

/* disable btn */
.disable {
    opacity: 0.5 !important;
}

/* checkbox */
.editor-checkbox {
    cursor: pointer;
    flex: none;
    width: 0.24rem;
    height: 0.24rem;
    border: 0.04rem solid #ffffff;
    outline: 0.01rem solid #D0D2DA;
    outline-offset: -0.01rem;
    background-color: #ffffff;

    &.checked {
        background-color: #F58C78;
    }
}

/* icon  */
.icon-close {
    display: inline-block;
    background: url('../images/icon_close.png') no-repeat center center;
    background-size: 100% 100%;
    min-width: 0.12rem;
    min-height: 0.12rem;
    cursor: pointer;
}

.icon-back {
    display: inline-block;
    background: url('../images/icon_back.png') no-repeat center center;
    background-size: 100% 100%;
    min-width: 0.08rem;
    min-height: 0.14rem;
    cursor: pointer;
}


/* form 表单元素 */
.form-wraper {
    margin: 0.32rem auto;
}

.form-input {
    box-sizing: border-box;
    width: 100%;
    height: 0.44rem;
    margin-bottom: 0.01rem;
    background-color: #F6F6F8;
    padding: 0.06rem 0.18rem;
    display: flex;
    align-items: center;

    input {
        padding-right: 0.18rem;
        width: 100%;
        font-size: 0.14rem;
        height: 100%;
        border: none;
        background-color: #F6F6F8;
    }

    select {
        appearance: none;
        padding-right: 0.18rem;
        flex: auto;
        font-size: 0.14rem;
        height: 100%;
        border: none;
        background-color: #F6F6F8;
    }

    .ant-input-password {
        padding: 0;
        border: none !important;
        background-color: transparent;

        &.ant-input-affix-wrapper-focused {
            border: none !important;
            outline: none !important;
            box-shadow: none !important;
        }

        .ant-input,
        .ant-input::placeholder {
            color: #545C78;
        }
    }

    .input-required {
        flex: none;
    }
}

.form-textarea {
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    height: 1rem;
    margin-bottom: 0.01rem;
    background-color: #F6F6F8;
    padding: 0.06rem 0.18rem;
    display: flex;
    align-items: center;

    textarea {
        padding-right: 0.18rem;
        flex: auto;
        font-size: 0.14rem;
        height: 100%;
        border: none;
        resize: none;
        background-color: #F6F6F8;

        &:active,
        &:hover,
        &:focus {
            border: none;
            outline: none;
        }
    }

    .input-required {
        flex: none;
    }
}

/*  modal弹窗遮罩 */
.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #fff, $alpha:0.5);
    z-index: 10000;

    .modal {
        position: relative;
        padding: 0.6rem 1rem;
        width: 6rem;
        background-color: #fff;
        box-shadow: 0 0.04rem 0.12rem rgba(0, 0, 0, 0.15);

        .modal-title {
            color: #000000;
            font-size: 0.24rem;
            line-height: 0.32rem;
            text-align: center;
        }

        .modal-close {
            position: absolute;
            top: 0.21rem;
            right: 0.21rem;
        }

        .modal-content {}
    }
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Black.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-BlackItalic.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Bold.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-BoldItalic.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-ExtraBold.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-ExtraBoldItalic.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-ExtraLight.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-ExtraLightItalic.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Medium.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-MediumItalic.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Italic.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Light.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-LightItalic.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-SemiBold.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-SemiBoldItalic.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Thin.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-ThinItalic.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-Regular.ttf);
}
