.not-found {
  position: relative;
  height: 90vh;
  width: 100vw;
  text-align: center;


  .content {
    position: absolute;

    margin: auto;

    top: 0;

    left: 0;

    right: 0;

    bottom: 0;
    font-size: 40px;
  }
}