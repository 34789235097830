.custom-upload {
  width: 128px;
  // height: 128px;

  .upload-button {
    position: relative;
    background: none;
    width: 128px;
    // height: 128px;

    .default {
      position: absolute;
      width: 115px;
      height: 115px;
    }

    .camera {
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: 0;
      right: 0;
    }
  }
}

.custom-upload {
  .ant-upload.ant-upload-select-picture-card {
    border-radius: 50% !important;
    border: none !important;
    background-color: #FFFFFF !important;
  }
}

.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #1890ff;
}